import { Icon } from '@chakra-ui/react';

export const Email = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M19.6055 2.85352H3.60547C2.50547 2.85352 1.60547 3.75352 1.60547 4.85352V16.8535C1.60547 17.9535 2.50547 18.8535 3.60547 18.8535H12.6055V16.8535H3.60547V6.85352L10.5455 11.1935C11.1955 11.6035 12.0155 11.6035 12.6655 11.1935L19.6055 6.85352V11.8535H21.6055V4.85352C21.6055 3.75352 20.7055 2.85352 19.6055 2.85352ZM11.6055 9.85352L3.60547 4.85352H19.6055L11.6055 9.85352ZM18.6055 15.0635C18.6055 14.6135 19.1455 14.3935 19.4555 14.7135L22.2455 17.5035C22.4455 17.7035 22.4455 18.0135 22.2455 18.2135L19.4555 21.0035C19.1455 21.3135 18.6055 21.0935 18.6055 20.6435V18.8535H15.6055C15.0555 18.8535 14.6055 18.4035 14.6055 17.8535C14.6055 17.3035 15.0555 16.8535 15.6055 16.8535H18.6055V15.0635Z"
      fill="currentColor"
    />
  </Icon>
);
