// changing "value" will change the route path for that module
export const meshModules = {
  overview: {
    label: 'Overview',
    value: 'overview',
    apiValue: 'OVERVIEW',
  },
  goals: {
    label: 'Goals',
    value: 'goal',
    apiValue: 'GOAL',
  },
  initiatives: {
    label: 'Initiatives',
    value: 'initiative',
    apiValue: 'TASK',
  },
  review: {
    label: 'Reviews',
    value: 'review',
    apiValue: 'REVIEW',
  },
  engagement: {
    label: 'Engagement',
    value: 'engagement',
    apiValue: 'ENGAGEMENT',
  },
  oneOnOne: {
    label: '1:1s',
    value: 'oneOnOne',
    apiValue: 'ONE_ON_ONE',
  },
  feedback: {
    label: 'Feedback',
    value: 'feedback',
    apiValue: 'FEEDBACK',
  },
  develop: {
    label: 'Competencies',
    value: 'develop',
    apiValue: 'LEVEL_UP',
  },
  employee: {
    label: 'Employee',
    value: 'employee',
    apiValue: '',
  },
} as const;

export const meshModulesPathValues = Object.values(meshModules).map((module) => module.value);

export type MeshModulesPathValue = (typeof meshModulesPathValues)[number];
