import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { setCollapsibleCardPreference } from 'utils/helpers/collapsibleCardsUtils';

// Define types
type CardSection = 'myData' | 'myDirectTeam' | 'mySkipTeam' | 'myCompany';

interface CardState {
  isCardOpen: boolean;
}

interface InitialState {
  [key: string]: {
    [key: string]: CardState;
  };
}

const updateCardState = (
  state: InitialState,
  section: CardSection,
  card: string,
  isCardOpen: boolean
): InitialState => ({
  ...state,
  [section]: {
    ...state[section],
    [card]: { ...state[section][card], isCardOpen },
  },
});

const initialState: InitialState = {
  myData: {
    reviewsOverview: { isCardOpen: true },
    competenciesOverview: { isCardOpen: true },
    goalsOverview: { isCardOpen: true },
    oneOnOneOverview: { isCardOpen: true },
    myGoalsChart: { isCardOpen: true },
    myGoalsMetricCard: { isCardOpen: true },
    myCompetenciesSpiderChart: { isCardOpen: true },
    myFeedbackSharedVsReceivedChart: { isCardOpen: true },
  },
  myDirectTeam: {
    performanceCheckInsOverview: { isCardOpen: true },
    reviewsOverview: { isCardOpen: true },
    competenciesOverview: { isCardOpen: true },
    goalsOverview: { isCardOpen: true },
    oneOnOneOverview: { isCardOpen: true },
  },
  mySkipTeam: {
    performanceCheckInsOverview: { isCardOpen: true },
    reviewsOverview: { isCardOpen: true },
    competenciesOverview: { isCardOpen: true },
    goalsOverview: { isCardOpen: true },
    oneOnOneOverview: { isCardOpen: true },
  },
  myCompany: {
    reviewsOverview: { isCardOpen: true },
    competenciesOverview: { isCardOpen: true },
    performanceCheckInsOverview: { isCardOpen: true },
    goalsOverview: { isCardOpen: true },
    oneOnOneOverview: { isCardOpen: true },
  },
};

export const deerCollapsibleCardSlice = createSlice({
  name: 'deerCollapsibleCardSlice',
  initialState,
  reducers: {
    setIsCardOpen: (
      state,
      action: PayloadAction<{
        card: string;
        isCardOpen: boolean;
        localStorageKey?: string;
        section: CardSection;
      }>
    ) => {
      const { card, isCardOpen, localStorageKey, section } = action.payload;
      const updatedState = updateCardState(state, section, card, isCardOpen);

      if (localStorageKey) {
        setCollapsibleCardPreference({
          userPreference: { [localStorageKey]: isCardOpen },
        });
      }

      return updatedState;
    },

    setIsLandingPageCardOpen: (
      state,
      action: PayloadAction<{
        card: string;
        isCardOpen: boolean;
        localStorageKey?: string;
        section: CardSection;
      }>
    ) => {
      const { card, isCardOpen, localStorageKey, section } = action.payload;
      const updatedState = updateCardState(state, section, card, isCardOpen);
      if (localStorageKey) {
        setCollapsibleCardPreference({
          userPreference: { [localStorageKey]: isCardOpen },
        });
      }

      return updatedState;
    },
  },
});

export const { setIsCardOpen } = deerCollapsibleCardSlice.actions;

export default deerCollapsibleCardSlice.reducer;

export const useSelectIsCardOpen = (section: CardSection, card: string) =>
  useSelector(
    (state: { deerCollapsibleCardSlice: InitialState }) => state.deerCollapsibleCardSlice[section][card].isCardOpen
  );
